<template>
  <div>
    <b-card>
      <b-card-header>
        <h2>
          <feather-icon icon="LayersIcon" />
          {{ $t('Routes.newEquipment') }}
        </h2>
      </b-card-header>
      <b-card-body>
        <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
          <b-form @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
            <b-row>
              <b-col cols="12" md="4">
                <validation-provider #default="{ errors }" :name="$t('AppTI.equipment.provider')" rules="required">
                  <b-form-group :label="$t('AppTI.equipment.provider')">
                    <v-select id="marcas" v-model="equipmentData.proveedorTiId"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="texto" :options="providers"
                              :reduce="option => option.id" />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="4">
                <validation-provider #default="{ errors }" :name="$t('AppTI.equipment.model')" rules="required">
                  <b-form-group :label="$t('AppTI.equipment.model')">
                    <v-select id="marcas" v-model="equipmentData.tipoEquipoMarcaModeloId"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="texto" :options="models"
                              :reduce="option => option.id" />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="4">
                <validation-provider #default="{ errors }" :name="$t('AppTI.equipment.ram')">
                  <b-form-group :label="$t('AppTI.equipment.ram')" label-for="type">
                    <b-form-input type="number" placeholder="16 (Solo numeros)" v-model="equipmentData.gbRam" />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="4">
                <validation-provider #default="{ errors }" :name="$t('AppTI.equipment.screen')">
                  <b-form-group :label="$t('AppTI.equipment.screen')" label-for="type">
                    <b-form-input type="number" placeholder="14.5" v-model="equipmentData.pulgadasPatalla" />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="4">
                <validation-provider #default="{ errors }" :name="$t('AppTI.equipment.cpu')" rules="max:50">
                  <b-form-group :label="$t('AppTI.equipment.cpu')" label-for="type">
                    <b-form-input type="text" placeholder="i7-778k" v-model="equipmentData.procesador" />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="4">
                <validation-provider #default="{ errors }" :name="$t('AppTI.equipment.hardDrive')">
                  <b-form-group :label="$t('AppTI.equipment.hardDrive')" label-for="type">
                    <b-form-input type="number" placeholder="1200" v-model="equipmentData.gbDiscoDuro" />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="4">
                <validation-provider #default="{ errors }" :name="$t('AppTI.equipment.ip')" rules="max:250|ipAddress">
                  <b-form-group :label="$t('AppTI.equipment.ip')" label-for="type">
                    <b-form-input type="text" placeholder="10.0.10.2" v-model="equipmentData.ip" />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="4">
                <validation-provider #default="{ errors }" :name="$t('AppTI.equipment.serialNumber')"
                                     rules="required|max:30">
                  <b-form-group :label="$t('AppTI.equipment.serialNumber')" label-for="type">
                    <b-form-input type="text" placeholder="18291281921912" v-model="equipmentData.numeroSerie" />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="4">
                <validation-provider #default="{ errors }" :name="$t('AppTI.equipment.imei')">
                  <b-form-group :label="$t('AppTI.equipment.imei')" label-for="type">
                    <b-form-input type="text" placeholder="3320/00909/980" v-model="equipmentData.imei" />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="4">
                <validation-provider #default="{ errors }" :name="$t('AppTI.equipment.cost')" rules="required">
                  <b-form-group :label="$t('AppTI.equipment.cost')" label-for="type">
                    <b-form-input type="number" placeholder="333.45" v-model="equipmentData.costo" />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="4">
                <validation-provider #default="validationContext" :name="$t('AppTI.equipment.buyDate')" rules="required">
                  <b-form-group :label="$t('AppTI.equipment.buyDate')" label-for="fecha-compra">
                    <b-form-datepicker id="fecha-compra" v-model="equipmentData.fechaCompra" today-button close-button
                                       locale="es" :state="getValidationState(validationContext)" :placeholder="$t('AppTI.equipment.noDateSelected')"/>
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="4">
                <validation-provider #default="validationContext" :name="$t('AppTI.equipment.garantyExperitation')" rules="required">
                  <b-form-group :label="$t('AppTI.equipment.garantyExperitation')" label-for="fecha-expiration">
                    <b-form-datepicker id="fecha-expiration" v-model="equipmentData.fechaExpiracionGarantia"
                                       today-button close-button locale="es" :state="getValidationState(validationContext)" :placeholder="$t('AppTI.equipment.noDateSelected')"/>
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="4">
                <validation-provider #default="validationContext" :name="$t('AppTI.equipment.garantyExperitationExtended')">
                  <b-form-group :label="$t('AppTI.equipment.garantyExperitationExtended')"
                                label-for="fecha-expiration-extended">
                    <b-form-datepicker id="fecha-expiration-extended"
                                       v-model="equipmentData.fechaExpiracionGarantiaExtendida" today-button close-button locale="es"
                                       :state="getValidationState(validationContext)" :placeholder="$t('AppTI.equipment.noDateSelected')"/>
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="4">
                <validation-provider #default="validationContext" name="FechaExpirationContrtact">
                  <b-form-group :label="$t('AppTI.equipment.dateContractFinish')" label-for="fecha-expiration-contract">
                    <b-form-datepicker id="fecha-expiration-contract" v-model="equipmentData.fechaTerminoContrato"
                                       today-button close-button locale="es" :state="getValidationState(validationContext)" :placeholder="$t('AppTI.equipment.noDateSelected')"/>
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12">
                <validation-provider #default="{ errors }" :name="$t('AppTI.equipment.description')"
                                     rules="required|max:250">
                  <b-form-group :label="$t('AppTI.equipment.description')" label-for="description">
                    <b-form-textarea id="description" v-model="equipmentData.descripcion" rows="3"
                                     :placeholder="$t('AppTI.equipment.description')"/>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12">
                <validation-provider #default="{ errors }" :name="$t('AppTI.equipment.observations')"
                                     rules="required|max:250">
                  <b-form-group :label="$t('AppTI.equipment.observations')" label-for="observations">
                    <b-form-textarea id="observations" v-model="equipmentData.observaciones" rows="3"
                                     :placeholder="$t('AppTI.equipment.observations')" />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                      {{ errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row class="float-right mt-2 mb-2">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" type="submit">
                <b-spinner v-if="addingEquipment" small class="mr-1" />
                {{ $t('Lists.Add') }}
              </b-button>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BButton,
  BSpinner,
  BForm,
  BFormInput,
  BFormTextarea,
  BFormDatepicker,
  BFormGroup,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  required, max,
} from '@validations'
import tiService from '@/services/informationTecnology.service'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ref } from '@vue/composition-api'
import router from '@/router'
import vSelect from 'vue-select'
import optionService from '@/services/option.service'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BButton,
    BSpinner,
    BForm,
    BFormInput,
    BFormTextarea,
    BFormDatepicker,
    BFormGroup,
    BFormInvalidFeedback,

    ValidationObserver,
    ValidationProvider,

    vSelect,

  },
  data() {
    return {
      required,
      max,
    }
  },
  setup() {
    const equipmentData = ref({
      equipoId: 0,
      proveedorTiId: null,
      conversacionId: null,
      tipoEquipoMarcaModeloId: null,
      gbRam: 0,
      pulgadasPatalla: 0,
      procesador: '',
      gbDiscoDuro: '',
      ip: '',
      descripcion: '',
      observaciones: '',
      numeroSerie: '',
      imei: '',
      costo: 0,
      fechaCompra: null,
      fechaExpiracionGarantia: null,
      fechaExpiracionGarantiaExtendida: null,
      fechaTerminoContrato: null,
    })

    const equipmentBlank = {
      equipoId: 0,
      proveedorTiId: null,
      conversacionId: null,
      tipoEquipoMarcaModeloId: null,
      gbRam: 0,
      pulgadasPatalla: 0,
      procesador: '',
      gbDiscoDuro: '',
      ip: '',
      descripcion: '',
      observaciones: '',
      numeroSerie: '',
      imei: '',
      costo: 0,
      fechaCompra: null,
      fechaExpiracionGarantia: null,
      fechaExpiracionGarantiaExtendida: null,
      fechaTerminoContrato: null,
    }

    const resetEquipmentData = () => {
      equipmentData.value = JSON.parse(JSON.stringify(equipmentBlank))
    }

    const addingEquipment = ref(false)
    const models = ref([])
    const providers = ref([])
    const { createEquipment } = tiService()
    const { fetchOptions } = optionService()

    fetchOptions({ nombreDominio: 'tipoModelo' }, data => {
      models.value = data
    })

    fetchOptions({ nombreDominio: 'proveedoresTi' }, data => {
      providers.value = data
    })

    const {
      refFormObserver,
      resetForm,
      getValidationState,
    } = formValidation(resetEquipmentData)

    const onSubmit = () => {
      addingEquipment.value = true
      createEquipment(equipmentData.value)
        .then(() => {
          addingEquipment.value = false
          router.push({ name: 'apps-equipment-list' })
        })
    }
    return {
      refFormObserver,
      resetForm,
      getValidationState,
      equipmentData,
      addingEquipment,
      providers,
      models,
      onSubmit,
    }
  },
}
</script>
